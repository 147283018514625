.js-cookie-consent{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    margin-bottom: 0;
    border-radius: 0;
    .cookie-consent__message{
        line-height: 38px;
    }
}

.cookie {
position: fixed;
z-index: 9999999999;
left: 0;
bottom: 0;
right: 0;
background: #00D3A8;
color: white;
padding: 10px 10px 10px 10px;
display: none;
overflow: hidden;
}

.cookie p {
margin: 0;
font-size: 14px;
line-height: normal;
float: left;
margin-top:7px;

}
.cookie a.close_cookie {
position: relative;
float: right;
color: white;
padding: 5px 10px 0px 10px;
background: #13967B;
font-size: 14px;
margin-top:4px;
}
