.cta a{
  width: 100%;
  position: relative;
  font-weight: bold;
  font-size: 2rem;
  padding:1rem;
  text-align: center;
  border: 2px solid $color-theme;
  -webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;

  
}
.cta a:hover{
  width: 80%;
  position: relative;
  background-color: $color-theme;
  color:#fff;
  font-weight: bold;
  font-size: 1.8rem;
  padding:1rem;
  text-align: center;
  border: 2px solid $color-theme;
  -webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;

}

.footer_2{
	
	padding: 3rem 0;
	background-color: transparent;
	color:#444;
	font-size: .9rem;
	border-top:1px solid #ccc;
	
	a{

		font-size: .9rem;
	}

	.links ul {
	    list-style: none;
	    margin: 0;
	    padding: 0;
	}
	.nav-link {
	    display: block;
	    padding: 0;
	}
	.microText .fa, .microText .fab{
		width:25px;
	}
	h4{
		color:$head-color;
		font-size: 1.1rem;
	}
	.h5{
		color:$head-color;
		font-family: $font-family-sans-serif-bold;
	}
}
.copyright{
	background-color: #fff !important;
	color:#000;
	padding-bottom:30px;
	p{
		
		margin-bottom: 0px;
		font-size:.7rem
	}
}

.footer_2 .nav-link{
	display:initial;
}

.footer_2 a {
  color: #ccc;
  position: relative;
  text-decoration: none;
  transition: color .4s ease-out;
}

.footer_2 a:hover {
  color: $link-color;
  right: 0;
  text-decoration: none;
}

.footer_2 a:hover:after {
  border-color: $link-color;
  right: 0;
}

.footer_2 a:after {
  border-radius: 1em;
  border-top: .1em solid $link-color;
  content: "";
  position: absolute;
    right: 100%;
    bottom:-.1em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1),
              border-color .4s ease-out;
}

.footer_2 a:hover:after {
right: 0;
}
