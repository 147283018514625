
.bg-light{
	background-color: #fff !important;
}
.offcanvas-collapse {
    background-color: #fff;
}
.navbar-light .navbar-nav .nav-link {
   color: #8E5A93;
   font-family: $font-family-sans-serif-bold;
    letter-spacing: -0.06rem;
    font-size: 15px;
    padding-left:0.4rem !important;
    padding-right:0.4rem !important;
    }
.dropdown-item.active, .dropdown-item:active, .dropdown-menu li.active a {
    color: #fff;
    text-decoration: none;
    background-color: $color-theme;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0rem 1.5rem 0rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: $font-family-sans-serif-bold;  
       font-size: 15px; 
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: #444;
}
.dropdown-toggle::after {
  display: none;
  border:none;
  vertical-align: 0em;
}

.dropdown-toggle::after {
	font-family: "Font Awesome 5 Pro"; 
	content: "\f107";
	display: inline-block;	
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}
}

@media (max-width: 992px){
	
	.navbar-brand img{
		height:90px;
	}
	}
