.social-line-realease {
    background-color: #FFF;
    color: #FFFFFF;
}
.social-line {
    padding: 30px 0 20px 0;
    background-color: #f2f2f2;
}
.social-line .btn {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.btn {
    white-space: normal;
}
.btn-facebook {
    color: #FFFFFF;
    background-color: #3b5998;
    border-color: #3b5998;
    opacity: 0.8;
}
.btn-linkedin {
    color: #FFFFFF;
    background-color: #0976b4;
    border-color: #0976b4;
    opacity: 0.8;
}
.btn-youtube {
    color: #FFFFFF;
    background-color: #e52d27;
    border-color: #e52d27;
    opacity: 0.8;
}
.btn-round {
    border-radius: 30px;
}
.btn-instagram {
    color: #FFFFFF;
    background-color: #125688;
    border-color: #125688;
    opacity: 0.8;
}
.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook.active, .open > .btn-facebook.dropdown-toggle {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #FFFFFF;
    opacity: 1;
}
.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active, .btn-instagram.active, .open > .btn-instagram.dropdown-toggle {
    background-color: #125688;
    border-color: #125688;
    color: #FFFFFF;
    opacity: 1;
}
.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active, .btn-linkedin.active, .open > .btn-linkedin.dropdown-toggle {
    background-color: #0976b4;
    border-color: #0976b4;
    color: #FFFFFF;
    opacity: 1;
}
.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active, .btn-youtube.active, .open > .btn-youtube.dropdown-toggle {
    background-color: #e52d27;
    border-color: #e52d27;
    color: #FFFFFF;
    opacity: 1;
}