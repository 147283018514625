/* contact pagina */

.company-information .adres {
    margin-left: 28px;
    margin-top:-25px;
}
.company-information {
	.fa,.fab{
		width:30px;
	}
}


.contactform .form-control {
    border: none;
    border-radius: 0;
    font-size: 13px;
    font-style: italic;
}
.contactform .form-control {
	border: 1px dotted #ccc;
   // border-bottom: 1px solid $color-theme;
    background-color: #fff;
}
.formHint, .error{
	color:#BF2F1A;
	font-size: 13px;
	font-style: italic;
}

.subscribe-btn {
  width: 100%;
  position: relative;
  background-color: transparent;
  font-weight: bold;
  font-size: 1.5rem;
  padding:0.7rem;
  text-align: center; 
  border: 2px solid $color-theme;
    -webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;
  
}
.subscribe-btn:hover {
  background-color: $color-theme;
  color:#fff;
  font-weight: bold;
    -webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;


}

/* end */
