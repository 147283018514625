// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons);
//@import url(https://fonts.googleapis.com/css?family=Montserrat:400,300,500,700);

// Variables brand
//@import "site/variables";
//@import "site/mixins";

// Site bootstrap variables override
@import "app/variables";
@import "app/headers";
@import "app/notifications";

// Bootstrap
@import "bootstrap/bootstrap";
@import "../css/offcanvas";

// Font Awesome 5
@import "fontawesomepro/fontawesome.scss";
@import "fontawesomepro/brands.scss";
//@import "fontawesomepro/light.scss";
//@import "fontawesomepro/regular.scss";
@import "fontawesomepro/solid.scss";

// Animations
//@import "../css/animate";

// Plugins
//@import "../libs/lightbox-master/dist/ekko-lightbox.min";
//@import "../libs/slick-1.8.1/slick/slick";
//@import "../libs/slick-1.8.1/slick/slick-theme";
//@import "../libs/aos-master/dist/aos";



//@import "app/butswerk";
@import "site/site";
@import "app/cookies";
@import "app/contact";
@import "app/socials";
@import "app/navbars";
//@import "app/blog";
//@import "app/referenties";
//@import "app/products";
@import "app/footer_2";
//@import "app/svg";
//@import "site/components/parallax";
//@import "site/components/lightbox";


