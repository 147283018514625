.navbar.navbar-expand-lg.fixed-top,
.footer,
.parallax-window,
.page-header-wrapper,
.page-carousel{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
.parallax-window,
.page-header-wrapper,
.page-carousel{
    position: relative;
}
/*.big-map{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}*/
.big-map{

    width: 100%;
    height: 250px;
}

.page-header{
    background-color: $header-background;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0;
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-half{
        min-height: 50vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 25vh !important;
    }
    &.page-header-sm{
        min-height: 40vh !important;
    }
    &.page-header-md{
        min-height: 55vh !important;
    }
    &.page-header-lg{
        min-height: 70vh !important;
    }
    &.page-header-xl{
        min-height: 85vh !important;
    }
    &.page-header-1610{
        min-height: auto;
        height: auto;
        max-height: auto;
        padding-top: 62.5%; /* 16:9 Aspect Ratio */
    }
    &.page-header-169{
        min-height: auto;
        height: auto;
        max-height: auto;
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }
    &.page-header-43{
        min-height: auto;
        height: auto;
        max-height: auto;
        padding-top: 75%; /* 4:3 Aspect Ratio */
    }
    &.page-header-31{
        min-height: auto;
        height: auto;
        max-height: auto;
        //padding-top: 33.33%; /* 4:3 Aspect Ratio */
        padding-top: 28.33%; /* 4:3 Aspect Ratio */
    }
    &.page-header-21{
        min-height: auto;
        height: auto;
        max-height: auto;
        padding-top: 50%; /* 4:3 Aspect Ratio */
    }
    &.page-header-11{
        min-height: auto;
        height: auto;
        max-height: auto;
        padding-top: 100%; /* 4:3 Aspect Ratio */
    }
    @media (max-width: 575px){
        //min-height: 300px !important;
    }
    @media (max-width: 767px){
        //min-height: 400px !important;
    }
    @media (max-width: 991px){
        //min-height: 500px !important;
    }
    .content-center{
       position: absolute;
       top: 50%;
       left: 50%;
       z-index: 2;
       -ms-transform: translate(-50%,-50%);
       -webkit-transform: translate(-50%,-50%);
       transform: translate(-50%,-50%);
       text-align: center;
       color: #FFFFFF;
       width: 100%;
   }

    .motto{
        color: #FFFFFF;
        text-align: center;
        z-index: 2;
        position: relative;
    }

    .filter::after{
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .filter-primary::after{
        background-color: rgba($filter-primary, 0.5);
    }
    .filter-info::after{
        background-color: rgba($filter-info, 0.5);
    }
    .filter-success::after{
        background-color: rgba($filter-success, 0.5);
    }
    .filter-warning::after{
        background-color: rgba($filter-warning, 0.5);
    }
    .filter-danger::after{
        background-color: rgba($filter-danger, 0.5);
    }
    .container{
        color: $white-color;
        position: relative;
        z-index: 3;
    }
    .description{
        color: $white-color;
    }
}
.header-video{
    &.state-play{
        .filter::after{
            opacity: 0;
        }
        .video-text{
            opacity: 0;
        }

        .btn{
            opacity: 0;
        }

        &:hover{
            .btn{
                opacity: 1;
            }
        }
    }
}
video{
    position: absolute;
    width: 100%;
}

// For IE Browser

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .page-header{
        .content-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }
        .presentation-title{
            background: none !important;
        }
    }

    .subscribe-line{
        .form-group{
            .form-control{
                line-height: 50px !important;
                height: 50px !important;
            }
        }
    }

    .nav-tabs .nav-item .active.nav-link::before{
        bottom: 2px !important;
    }

    .nav-tabs .nav-item .active.nav-link::after{
        bottom: 1px !important;
    }
    .nav-stacked .active.nav-link::before{
        right: 0 !important;
        bottom: 7px !important;
    }
    .nav-stacked .active.nav-link::after{
        right: -2px !important;
        bottom: 0 !important;
        top: 13px !important;
    }
    .buy-product{
        .page-carousel{
            .carousel-inner{
                max-height: 570px !important;
            }
        }
    }
}

.item .page-header .container{
    padding-top: 0;
}
.home-slider-next{
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    a{
        //border-radius: 45px;
        //background: white;
        color: white;
        font-size: 40px;
        line-height: 1;
        //line-height: 40px;
        //width: 40px;
        //height: 40px;
        text-align: center;
        display: inline-block;
        .fa{
            width: auto;
            line-height: 1;
        }
    }
}
.item .btn{
    margin-top: -20px;
    margin-bottom: 40px;
    color: black;
    font-weight: bold;
}
.slider-title.text-white{
    color: white;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.slider-title.text-black{
    color: black;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.text-left.slider-text,
.text-right.slider-text{
    .text{
        display: block;
    }
}
.slider-text{
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0,0,0);
    color: #fff;
    font-family: $font-family-sans-serif;
    
    h2{
	    margin-top: 0px;
    }

    .text-sub{
        display: block;
        padding-bottom: 15px;
    }

    .text{
        //text-transform: uppercase;
        line-height: 1.3;
        letter-spacing: 1px;
        //font-weight: bold;
        //line-height: 1.7em;
        display: inline-block;
        //margin: 0 17px;
        //font-family: $title-font;
        //padding: 8px 16px;
        //font-family: 'Abside';
        &.text-xs{
            font-size: 14px;
            h2{
                font-size: 21px;
            }
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 767px) {
                font-size: 11px;
            }
        }
        &.text-sm{
            font-size: 24px;
            h2{
                font-size: 29px;
            }
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        &.text-md{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 28px;
            }
            @media (max-width: 991px) {
                font-size: 24px;
            }
            @media (max-width: 767px) {
                font-size: 20px;
            }
            h2{
                font-size: 42px;
                @media (max-width: 1199px) {
                    font-size: 36px;
                    line-height: 1.2;
                }
                @media (max-width: 991px) {
                    font-size: 30px;
                    line-height: 1.2;
                }
                @media (max-width: 767px) {
                    font-size: 24px;
                    line-height: 1.2;
                }
            }
            @media (max-width: 1199px) {
                font-size: 27px;
            }
            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        &.text-lg{
            font-size: 50px;
            font-weight: 700;
            p{
                font-size: 15px;
                line-height: 1.4;
            }
            .h2{
                font-size: 3rem;
                line-height: 1.1;
                @media (max-width: 1199px) {
                font-size: 3rem;
                line-height: 1.1;
                }
                @media (max-width: 991px) {
                font-size: 3rem;
                line-height: 1.1;
                }
                @media (max-width: 767px) {
                font-size: 2.4rem;
                line-height: 1.1;
                }
            }
            @media (max-width: 1199px) {
                //font-size: 40px;
            }
            @media (max-width: 991px) {
                //font-size: 32px;
            }
            @media (max-width: 767px) {
                //font-size: 24px;
            }
        }
        &.text-xl{
            p{
                font-size: 15px;
                line-height: 1.4;
            }
            h2{
                font-size: 2.825em;
                line-height: 1.4;
                @media (max-width: 1199px) {
                    font-size: 2.625em;
                    line-height: 1.2;
                }
                @media (max-width: 991px) {
                    font-size: 2.425em;
                    line-height: 1.2;
                }
                @media (max-width: 767px) {
                    font-size: 2.225em;
                    line-height: 1.2;
                }
            }
            @media (max-width: 1199px) {
                //font-size: 52px;
            }
            @media (max-width: 991px) {
                //font-size: 40px;
            }
            @media (max-width: 767px) {
                //font-size: 32px;
            }
        }
    }
}
.carousel-text-position-top,
.carousel-text-position-middle,
.carousel-text-position-bottom{
    position: absolute;
    left: 0;
    right: 0;
}
.carousel-text-position-top{
    top: 100px;
}
.carousel-text-position-middle{
    top: 52%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10000000;
    @media (max-width: 767px) {}
}
.carousel-text-position-bottom{
    bottom: 10%;
    @media (max-width: 767px) {
        bottom: 50px;
    }
}
.carousel-indicators .active{
    background-color: $info-color;
}
.carousel-control{
    //background-color: $info-color;
    //border-radius: 50%;
    opacity: 1;
    background: none;
    text-shadow: none;
    &:hover{
        //background-color: $info-color;
        background: none;
    }
}
.slider-with-intro-arrow .carousel-text-position-bottom{
    bottom: 55px;
}
.page-header{
    //border-bottom: 5px solid $brand-dark;
}
.page-header.page-header-fs{
    min-height: 100vh;
    min-height: calc(100vh - 83px);
    height: auto;
    @media (max-width: 767px) {
        min-height: 60vh;
    }
}
.page-header.page-header-hs {
  min-height: 45vh;
  height: auto;
}
.page-header.page-header-25 {
  min-height: 30vh;
  height: auto;
}
.page-header.page-header-75 {
  min-height: 70vh;
  height: auto;
}
