.first_name{
	display:none;
}

body{
	background-color: #fff;
	padding-top:129px;
	color:#444;	
	h1,h2,h3,h4,h5{
		color: $head-color;
		font-family: "Inter-Bold", sans-serif;
		letter-spacing: -0.06rem;
	}
	a:hover{
		text-decoration: none;
	}
	blockquote{
		p{
			margin:0px;
		}
		background-color: #ccc;
		padding:1rem;
		color:#fff;
	}
	b, strong {
    font-weight: normal;
    font-family: "Inter-Bold", sans-serif;
	}
}
.wrapper{

	background-image: url(../img/website-bg.jpg);
	background-position: right bottom;
	background-repeat: no-repeat;
	max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
}
.section{
	padding: 3rem 0;
	background-color: transparent;
	&.home{
	padding: 6rem 0;	
	}
}
.bold{
	font-family: "Inter-Bold", sans-serif;
	font-size: 1.2rem;
	color:$head-color;
}

h1{
	font-size: 2.8rem;
	font-family: "Inter-Bold", sans-serif;
    letter-spacing: -0.06rem;
    @media (max-width: 992px) { 
		font-size: 2rem;
		}
}

.vertical{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.introtekst{	
	padding-left:40px; 
	p{
	font-size:1.2rem;
	font-family: "Inter-Bold", sans-serif;
	}
	@media (max-width: 576px) { 
		margin-top: 40px;
		
		}
}
.pagetext{
	padding-left:40px; 
	margin-top:150px;
	@media (max-width: 992px) { 
		margin-top:30px;
		}
}

a.direct-contact, .direct-contact {
    display: inline-block;
    padding: 5px 10px;
    border-bottom: 4px solid #444;
    background-color: $link-color;
    text-align: center;
    font-size: 1rem;
    color: #fff !important;
}
.quotblok{
	background-color: rgba(241,241,241, 0.8);
	padding:2rem;
	padding-left:3rem;
	margin-bottom:30px;
	.kolibrie{
		width:92px;
		height: 103px;
		position: absolute;
		top:-20px;
		left:-30px;
		background-image: url(../img/kolibrie-solo.png);
		background-repeat: no-repeat;
	}
	.title{
		color:$head-color;
		font-family: "Inter-Bold", sans-serif;
	}
}